<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_dps',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Jenis Program',
          dataKey: 'jenis_program',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Nama Program Studi',
          dataKey: 'nama_program_studi',
          order: 2,
          attributes: { rowspan: 2 },
        }, {
          label: 'Akreditasi Program Studi',
          attributes: { colspan: 4 },
        }, {
          label: 'Jumlah Mahasiswa saat TS',
          dataKey: 'jumlah_mhs_ts',
          order: 7,
          attributes: { rowspan: 2 },
        }, {
          label: 'Jumlah DTPS saat TS',
          dataKey: 'jumlah_dtps_ts',
          order: 8,
          attributes: { rowspan: 2 },
        }, {
          label: 'Rerata IPK',
          dataKey: 'rata_ipk',
          order: 9,
          attributes: { rowspan: 2 },
        }, {
          label: 'Rerata Masa Studi',
          dataKey: 'rata_masa_studi',
          order: 19,
          attributes: { rowspan: 2 },
        }],
        [{
          label: 'Status/Peringkat',
          dataKey: 'status_peringkat',
          order: 3,
        }, {
          label: 'Nomor SK',
          dataKey: 'nomor_sk',
          order: 4,
        }, {
          label: 'Tanggal SK',
          dataKey: 'tanggal_sk',
          order: 5,
          render(data) {
            return moment(data).format('DD-MM-YYYY')
          }
        }, {
          label: 'Tanggal Kadaluarsa',
          dataKey: 'tanggal_kadaluarsa',
          order: 6,
          render(data) {
            return moment(data).format('DD-MM-YYYY')
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Jenis Program',
          name: 'jenis_program',
          dataKey: 'jenis_program',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Nama Program Studi',
          name: 'nama_program_studi',
          dataKey: 'nama_program_studi',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Status / Peringkat',
          name: 'status_peringkat',
          dataKey: 'status_peringkat',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Nomor SK',
          name: 'nomor_sk',
          dataKey: 'nomor_sk',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Tanggal SK',
          name: 'tanggal_sk',
          dataKey(data) {
            return moment(data.tanggal_sk).format('DD-MM-YYYY')
          },
          type: 'date',
          rules: 'required'
        }, {
          label: 'Tanggal Kadaluarsa',
          name: 'tanggal_kadaluarsa',
          dataKey(data) {
            return moment(data.tanggal_kadaluarsa).format('DD-MM-YYYY')
          },
          type: 'date',
          rules: 'required'
        }, {
          label: 'Jumlah Mahasiswa saat TS',
          name: 'jumlah_mhs_ts',
          dataKey: 'jumlah_mhs_ts',
          type: 'number',
          rules: 'required'
        }, {
          label: 'Jumlah DTPS saat TS',
          name: 'jumlah_dtps_ts',
          dataKey: 'jumlah_dtps_ts',
          type: 'number',
          rules: 'required'
        }, {
          label: 'Rerata IPK',
          name: 'rata_ipk',
          dataKey: 'rata_ipk',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Rerata Masa Studi',
          name: 'rata_masa_studi',
          dataKey: 'rata_masa_studi',
          type: 'text',
          rules: 'required'
        }],
        customPayload(data) {
          return {
            ...data,
            tanggal_sk: moment(data.tanggal_sk, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            tanggal_kadaluarsa: moment(data.tanggal_kadaluarsa, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          }
        }
      }
    }
  },
}
</script>
